<template>
  <div class="sigle_add">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="学科">
        <el-select v-model="discipline" placeholder="请选择对应学科">
          <el-option label="JAVA" value="Java分布式开发V12"></el-option>
          <el-option label="HTML5" value="HTML5大前端就业班V12"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程阶段" prop="c_phase">
        <!-- <el-input v-model.number="ruleForm.c_phase"></el-input> -->
        <el-select
          v-model="ruleForm.c_phase"
          placeholder="请选择对应学科"
          @change="changeAction"
        >
          <el-option
            :label="item.jieduanming"
            v-for="(item, index) in phases"
            :key="index"
            :value="item.jieduanming"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程名称" prop="c_name">
        <el-select v-model="ruleForm.c_name" placeholder="请选择对应课程">
          <el-option
            :label="item.kechengming"
            v-for="(item, index) in names"
            :key="index"
            :value="item.kechengming"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="题目" prop="title">
        <el-input
          type="text"
          v-model="ruleForm.title"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="答案" prop="answer">
        <quill-editor
          ref="text"
          v-model="ruleForm.answer"
          class="myQuillEditor"
        />
      </el-form-item>
      <el-form-item label="分值" prop="score">
        <el-input v-model.number="ruleForm.score"></el-input>
      </el-form-item>
      <el-form-item label="评分标准" prop="biaozhun">
        <el-input v-model.number="ruleForm.biaozhun"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// 导入富文本编辑器
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "SingleAdd",
  data() {
    var validateTitle = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("题目不能为空"));
      } else {
        callback();
      }
    };
    var validateC_name = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("课程名称不能为空"));
      } else {
        callback();
      }
    };
    var validateAnswer = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("题目答案不能为空"));
      } else {
        callback();
      }
    };
    var validateScore = (rule, value, callback) => {
      if (value === "" || value === 0) {
        callback(new Error("分值不能为空"));
      } else {
        callback();
      }
    };
    var validateBiaoZhun = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("评分标准不能为空"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        title: "",
        answer: "",
        c_name: "",
        c_phase: "",
        score: 10,
        biaozhun: "",
        bianhao: "",
      },
      discipline: "HTML5大前端就业班V12",
      phases: [],
      names: [],
      kechengs: [],
      rules: {
        title: [{ validator: validateTitle, trigger: "blur" }],
        c_name: [{ validator: validateC_name, trigger: "blur" }],
        answer: [{ validator: validateAnswer, trigger: "blur" }],
        score: [{ validator: validateScore, trigger: "blur" }],
        biaozhun: [{ validator: validateBiaoZhun, trigger: "blur" }],
      },
      courses: [],
    };
  },
  async created() {
    let res = await this.$datas.getCourses;
    this.courses = res.data;
    this.filterData();
  },
  watch: {
    discipline: {
      async handler(newV) {
        this.discipline = newV;
        this.filterData();
      },
      immediate: false,
    },
  },
  methods: {
    filterData() {
      let jieduan = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.kechengtixi == this.discipline) {
          var orExist = false;
          for (let j in jieduan) {
            if (jieduan[j].jieduanming == item.jieduanming) {
              orExist = true;
              break;
            }
          }
          if (!orExist) {
            jieduan.push({
              jdbianhao: item.jdbianhao,
              jieduanming: item.jieduanming,
            });
          }
        }
      }
      this.phases = jieduan;
    },
    async submitForm() {
      for (let i in this.courses) {
        let item = this.courses[i];
        if (
          item.kechengtixi == this.discipline &&
          item.jieduanming == this.ruleForm.c_phase &&
          item.kechengming == this.ruleForm.c_name
        ) {
          this.ruleForm.bianhao = `${item.jdbianhao}-${item.kcbianhao}`;
        }
      }
      console.log(this.ruleForm);
      this.$datas.meta = {
        ruleForm: { ...this.ruleForm, discipline: this.discipline },
      };
      let res = await this.$datas.singleAdd;
      if (res.status >= 200 && res.status < 300) {
        this.$alert("考题已添加", "提示", {
          confirmButtonText: "确定",
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeAction(kind) {
      let names = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.jieduanming == kind && this.discipline == item.kechengtixi) {
          names.push({
            kcbianhao: item.kcbianhao,
            kechengming: item.kechengming,
          });
        }
      }
      this.names = names;
    },
  },
  components: {
    quillEditor,
  },
};
</script>
<style lang="less">
.ql-editor {
  min-height: 200px;
}
</style>
