<template>
	<div class="tk_add">
		<div class="btns">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="单题导入" name="first">
                    <single-add></single-add>
                </el-tab-pane>
				<el-tab-pane label="批量导入" name="second">
                    <pl-add></pl-add>
                </el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>
<script>
//导入单题导入组件和批量导入组件
import SingleAdd from "./SingleAdd.vue";
import PlAdd from "./PLAdd.vue";
export default {
	name: 'TKAdd',
    data() {
      return {
        activeName: 'first'
      };
    },
    methods: {
      handleClick(tab) {
        console.log(tab.index);
      }
    },
    components:{
        SingleAdd,
        PlAdd
    }
}
</script>
